<template>
  <div class="space-y-4 w-full" data-cy="select-branding-wrapper">
    <div
      class="flex items-center justify-between"
      :class="{ 'cursor-pointer': isToggleable }"
      @click="isOpen = !isOpen"
    >
      <span class="text-headline">
        {{ $t("COMMON.CHOOSE") }}
        {{ $t("COMMON.BRANDING") }}
      </span>
      <icon-button v-if="isToggleable">
        <dropdown-chevron
          :is-open="isOpen"
          :is-default-position="false"
          class="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-grey-icon icon-base"
        />
      </icon-button>
    </div>
    <div
      v-if="(isToggleable && isOpen) || !isToggleable"
      class="flex flex-wrap gap-4"
      data-cy="email-brandings"
    >
      <div class="branding-wrapper">
        <icon-base
          :icon="IconEmailBrandingPreview"
          width="90"
          height="64"
          class="rounded"
        />
        <label class="branding-label">
          <input
            type="radio"
            :checked="selectedBrandingId === null"
            class="cursor-pointer mt-0-25"
            @click="selectedBrandingId = null"
          />
          <span class="text-headline">
            {{ $t("COMMON.NO") }}
            {{ $t("COMMON.BRANDING").toLowerCase() }}
          </span>
        </label>
      </div>
      <div v-if="hasDefault" class="branding-wrapper">
        <icon-base
          :icon="IconEmailBrandingPreview"
          width="90"
          height="64"
          class="rounded text-woods-green"
        />
        <label class="branding-label">
          <input
            type="radio"
            :checked="selectedBrandingId === EmailBrandingId.Default"
            data-cy="default-branding"
            class="cursor-pointer mt-0-25"
            @click="selectedBrandingId = EmailBrandingId.Default"
          />
          <span class="text-headline">
            {{ $t("COMMON.DEFAULT") }}
            {{ $t("COMMON.BRANDING").toLowerCase() }}
          </span>
        </label>
      </div>
      <email-branding
        v-for="template in brandings"
        :key="template.id"
        :template="template"
        v-model="selectedBrandingId"
        class="w-36"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TestEmailData } from "@/models/emails";
import { ref, type PropType } from "vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconEmailBrandingPreview from "@/components/icons/IconEmailBrandingPreview.vue";
import EmailBranding from "@/views/deals/components/emails/EmailBranding.vue";
import IconButton from "@/components/ui/buttons/IconButton.vue";
import DropdownChevron from "@/components/ui/DropdownChevron.vue";
import { EmailBrandingId } from "@/enums/emailCustomization";

defineProps({
  brandings: {
    type: Array as PropType<TestEmailData["email_customizations"]>
  },
  hasDefault: {
    type: Boolean,
    default: false
  },
  isToggleable: {
    type: Boolean,
    default: false
  }
});

const selectedBrandingId = defineModel<number | null>();

const isOpen = ref(false);
</script>
<style scoped>
.branding-wrapper {
  @apply flex flex-col space-y-2 w-36;
}

.branding-label {
  @apply flex cursor-pointer space-x-2;
}
</style>
